<template>
    <div class="staff-landing">
        <HeroBasic
            :heading="title"
            accentColor="grey"
            :richText="dek"
            class="page-heading"
            :showTopographic="true"
            :hasHeaderOffset="true"
        />

        <IndexTier :contentType="contentType" :renderedFilters="renderedFilters" :pageId="id" :limit="400">
            <template #results="{ results }">
                <template v-for="(result, index) in results">
                    <div v-if="result" class="result" :key="index">
                        <div class="person">
                            <Typography as="h4" variant="h4-display" class="title">{{ result.title }} </Typography>
                            <Typography as="div" variant="body-display-small-italic" class="role"
                                >{{ result.summary?.personTitle }}
                            </Typography>
                        </div>
                    </div>
                </template>
            </template>
        </IndexTier>
    </div>
</template>

<script setup>
import centers from '@/constants/centers.js';

defineProps({
    title: {
        type: String,
        default: '',
    },
    dek: {
        type: Object,
        default: () => {},
    },
    contentType: {
        type: String,
        default: '',
    },
    id: {
        type: String,
    },
});

const renderedFilters = [];

const headerAttributesStore = useHeaderAttributesStore();
onMounted(() => {
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'transparent' });
});
</script>

<style lang="scss" scoped>
.page-heading {
    background: color(grey, light);
    border-bottom: 0.1rem solid palette(border-light);
}

.result {
    padding-top: vertical-space(1);
    padding-bottom: vertical-space(1);
    align-items: center;

    @include grid;
    @include content-area;

    @include media-query(phone) {
        display: block;
    }

    &:not(:last-child) {
        border-bottom: 1px solid palette(bg-gray-dark);
    }

    .person {
        grid-column: 1 / span 8;
        display: block;
        gap: vertical-space(1);
        align-items: center;

        @include media-query(phone) {
            margin-bottom: vertical-space(1);
            flex-direction: row-reverse;
            justify-content: space-between;
        }

        .details {
            flex-grow: 1;
        }

        .title {
            color: color(blue, default, 0.8);
        }

        .role {
            color: color(noir, default, 0.6);
        }
    }

    .features {
        display: none;

        @include media-query(full) {
            display: block;
            grid-column: 7 / span 3;
            @include padding-tb(vertical-space(1));
        }
    }
}
</style>
